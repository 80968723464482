@import "../node_modules/@fortawesome/fontawesome-pro/css/all.min.css";
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";
@import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

/* For preview cutting bug */
html {
  overflow-y: auto !important;
  font-size: 16px;
}

* {
  font-family: "HDI Pro", Arial, sans-serif;
}

@font-face {
  font-family: "HDI Pro";
  font-display: swap;
  font-weight: normal;
  src:
    url("./assets/fontsPro/HDIPro-Book.woff2") format("woff2"),
    url("./assets/fontsPro/HDIPro-Book.woff") format("woff"),
    url("./assets/fontsPro/HDIPro-Book.ttf") format("truetype"),
    url("./assets/fontsPro/HDIPro-Book.otf") format("opentype");
}

@font-face {
  font-family: "HDI Pro";
  font-display: swap;
  font-weight: 500;
  src:
    url("./assets/fontsPro/HDIPro-Medium.woff2") format("woff2"),
    url("./assets/fontsPro/HDIPro-Medium.woff") format("woff"),
    url("./assets/fontsPro/HDIPro-Medium.ttf") format("truetype"),
    url("./assets/fontsPro/HDIPro-Medium.otf") format("opentype");
}

@font-face {
  font-family: "HDI Pro";
  font-display: swap;
  font-weight: bold;
  src:
    url("./assets/fontsPro/HDIPro-Bold.woff2") format("woff2"),
    url("./assets/fontsPro/HDIPro-Bold.woff") format("woff"),
    url("./assets/fontsPro/HDIPro-Bold.ttf") format("truetype"),
    url("./assets/fontsPro/HDIPro-Bold.otf") format("opentype");
}
